import HeaderScectionCss from '../CssComponentModule/Header.module.css';
import Mixo_logo from '../../Assets/Images/app logo unit 2 1.png'
import ronin_logo from '../../Assets/Images/slash-01 1.png'
import { useNavigate, useLocation } from 'react-router-dom';

const HeaderSection = () => {
    const navigate = useNavigate();
    const onClickHome = () => {
        navigate('/')
    }
    return (
        <div className={HeaderScectionCss.headerSection}>
            <div className={HeaderScectionCss.mixoIcon}>
                <span onClick={onClickHome}>
                    <img src={Mixo_logo} className={HeaderScectionCss.mixoLogo} />

                </span>
            </div>
            <div className={HeaderScectionCss.logo} >
                <p className={HeaderScectionCss.craft}>crafted at </p>
                <a href='https://www.ronin.co.in/' target="_blank">
                    <img src={ronin_logo} className={HeaderScectionCss.roninLogo} />
                </a>
            </div>
        </div>
    )

}

export default HeaderSection;