import './App.css';
import HomeScreen from './Components/ScreenComponents/HomeScree';
import PrivacyPolicy from './Components/ScreenComponents/PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderSection from './Components/SectionComponent/HeaderSection';
function App() {
  return (
    <Router>
      <HeaderSection />
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
