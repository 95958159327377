import PrivacyPolicycss from "../../Components/CssComponentModule/PrivacyPolicy.module.css"
const PrivacyPolicy = () => {
    return (
        <div className={PrivacyPolicycss.containHeader}>

            <div className={PrivacyPolicycss.HeaderText}>
                <p>
                    Mixology  <span className={PrivacyPolicycss.xrtxt}>XR</span>
                </p>
            </div>
            <div className={PrivacyPolicycss.contains}>
                <div className={PrivacyPolicycss.maincontain}>
                    <p className={PrivacyPolicycss.text}>
                        <br /><span className={PrivacyPolicycss.black}>1. Introduction</span><br />
                        We developed the MixologyXR app as a commercial application. This service is provided by Ronin labs and is intended for use as is.
                        This Privacy Policy outlines our policies regarding the collection, use, and disclosure of personal information when you use our
                        Service. By accessing MixologyXR, our website ronin.co.in or any related services, you agree to the terms outlined in this Privacy
                        Policy.<br /><br />
                        <span className={PrivacyPolicycss.black}>2. Information Collection and Use</span><br />
                        We aim to provide a seamless and enjoyable experience for our users. To achieve this, we collect certain information to improve and
                        personalise your experience. We are committed to respecting your privacy and ensuring that your personal data is protected.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            2.1 Types of Information Collected <br />
                            2.1.1 Personal Information<br />
                        </span>
                        We do not collect personal information such as names, physical addresses, contact details, or geolocation data directly. However,
                        you may choose to provide such information voluntarily through communications with us or other interactions with our services.<br /><br />

                        <span className={PrivacyPolicycss.black}>2.1.2 Non-Personal Information We collect non-personal information to improve our services</span><br />
                        This includes:<br />
                        <span className={PrivacyPolicycss.black_small}>
                            Technical Details:
                        </span>
                        When you use, play, watch, or access the MixologyXR Services, we or third parties on our behalf may collect
                        technical details about the device you are using, including: internet and/or network connection (including IP address); MAC
                        address, any console device identifier; your operating system, browser type, or other software; and your hardware or other
                        technical details.<br /><br />
                        <span className={PrivacyPolicycss.black_small}>Usage Data:</span>
                        When you use, play, watch, or access the MixologyXR Services, we or third parties on our behalf may collect details
                        of how you use our services, including: metrics information about when and how you use the services; traffic data; language
                        preferences; in-game purchases; achievements; scores; use statistics; time spent playing; and your geographical location data.<br /><br />
                        <span className={PrivacyPolicycss.black_small}>Communication Records:</span>

                        If you contact us (e.g., via email or call for customer support) or post in chat sessions, forums, on our
                        social media pages, or other areas of the MixologyXR Services, we may collect and maintain a record of your account/contact
                        details and the content of your communications.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            2.2 Storage of Payment Details
                        </span><br />
                        The processing and storage of payment details is a matter for the relevant platform and the applicable payment
                        methods/processors. MixologyXR will not receive, process, or store any of your payment details. This is because the payment
                        transactions themselves are completed through the relevant platforms (e.g., PayPal, Steam, Apple’s App Store, or Google Play) via
                        the user account you have associated with that platform account. If you make in-game purchases within a MixologyXR game or
                        product, we are notified by the payment processor once a transaction takes place but we will not receive any of your actual payment
                        details.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            2.3 Methods of Collection<br />
                            2.3.1 Direct Collection<br />
                        </span>

                        Information that you provide directly to us, such as through customer support requests or feedback forms.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            2.3.2 Automated Collection
                        </span><br />
                        We use various technologies to collect non-personal information automatically, including cookies, web beacons, and other tracking
                        technologies.<br /><br />
                        <span className={PrivacyPolicycss.black}>3. Use of Collected Information<br />
                            3.1 Improving Services </span><br />

                        We use the collected information to analyse and improve the performance and user experience of our game. This includes optimising
                        gameplay, fixing bugs, and enhancing features.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            3.2 Communication
                        </span><br />

                        We may use your contact information to send you updates, respond to your inquiries, and provide customer support.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            3.3 Aggregated Data
                        </span><br />
                        We aggregate non-personal information to understand user behaviour and preferences. This aggregated data does not identify
                        individual users and is used for statistical analysis and research.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            4. Sharing and Disclosure of Information<br />
                            4.1 Third-Party Service Providers<br />
                        </span>
                        We may engage third-party companies and individuals to facilitate our services, provide the services on our behalf, perform service
                        related activities, or assist us in analysing how our service is used. These third parties have access to your information only to
                        perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.<br /><br />

                        <span className={PrivacyPolicycss.black}>
                            4.2 Legal Requirements

                        </span><br />
                        We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or
                        government agency).<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            5. Data Security

                        </span><br />

                        We value your trust in providing us with your information and strive to use commercially acceptable means to protect it. However,
                        no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee
                        its absolute security.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            6. Children's Privacy

                        </span><br />

                        Our services are not directed at children under the age of 13. We do not knowingly collect personally identifiable information from
                        children under 13. If we become aware that a child under 13 has provided us with personal information, we will delete such
                        information from our servers immediately. If you are a parent or guardian and you are aware that your child has provided us with
                        personal information, please contact us so that we can take the necessary actions.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            7. International Data Transfers

                        </span><br />

                        Your information, including personal data, may be transferred to and maintained on computers located outside of your state,
                        province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. By
                        using our Service, you consent to such transfers.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            8. Data Retention

                        </span><br />

                        We retain your information for as long as needed to provide the MixologyXR Services to you or longer where permitted by law.
                        How long do we retain your data for?
                        As long as necessary for the purpose for which the data was originally collected. Depending on where you live, you may have certain
                        legal rights to object to our collection and use of your data, but this may mean some or all MixologyXR Services become unavailable
                        to you. We also reserve the right to retain your information as necessary to comply with our legal obligations, resolve disputes,
                        maintain appropriate business records, and enforce our agreements.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            9. Your Rights
                        </span><br />
                        Regardless of your location, you have the right to access, correct, or delete your personal data at any time.
                        If you wish to exercise any of these rights, please contact us at  <span>
                            <a href="mailto: sumeet@ronin.co.in">
                                sumeet@ronin.co.in
                            </a>
                        </span>. We will review and respond to your request promptly, in compliance with applicable data protection laws,
                        and ensure your rights are respected without any geographic restrictions.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            10. Changes to This Privacy Policy

                        </span><br />

                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this
                        page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
                        immediately after they are posted on this page.<br /><br />
                        <span className={PrivacyPolicycss.black}>
                            11. Contact Us

                        </span><br />

                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
                        <span>
                            <a className={PrivacyPolicycss.space} href="mailto: sumeet@ronin.co.in">
                                sumeet@ronin.co.in
                            </a>
                        </span><br /><br />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy