import Lottie from 'react-lottie';
import HomeCss from '../CssComponentModule/HomeScreen.module.css'
import Mixo_logo from '../../Assets/Images/app logo unit 2 1.png'
import ronin_logo from '../../Assets/Images/slash-01 1.png'
import animation from '../../Assets/Animation/Animation - 1727083370209.json'
import discordIcon from '../../Assets/Images/Socal Icons/discord.png';
import instaIcon from '../../Assets/Images/Socal Icons/instagram 1.png'
import facebookIcon from '../../Assets/Images/Socal Icons/facebook.png'
import xIcon from '../../Assets/Images/Socal Icons/twitter.png'
import { Link, useNavigate } from "react-router-dom";
const HomeScreen = () => {
    const navigate = useNavigate();
    const onClickprivacy = () => {
        navigate("/privacy")
    }

    return (
        <div className='wrapper overlapClass'>

            {/* HomeSection */}

            <div className={HomeCss.Main_Section} >
                <div className={HomeCss.top_text}>
                    <p className={HomeCss.xr_text}>
                        Mixology XR
                    </p>
                </div>
                <div className={HomeCss.Heading}>
                    <p className={HomeCss.comingSoonTxt}>Coming Soon</p>
                </div>
            </div>


            <div className={HomeCss.main_animation}>
                <div className={HomeCss.videoAnimation}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animation,
                        }}
                        padding="0"
                        margin="0"
                        loading="lazy"
                        alt=""
                    />
                </div>
            </div>

            <div className={HomeCss.footer_Section}>
                <div className={HomeCss.main_footer}>
                    <div className={HomeCss.footer_txt}>
                        <span onClick={onClickprivacy}>
                            <p className={HomeCss.privacyTxt}>Privacy Policy</p>
                        </span>
                    </div>
                    <div className={HomeCss.socalIcons}>
                        <a href='https://discord.gg/jkRF3gZBBY' target="_blank" >
                            <img src={discordIcon} className={HomeCss.iconSize} />
                        </a>
                        <a href='https://www.instagram.com/roninlabsmixologyxr?igsh=aTExNTNpcTlncmJm' target="_blank">
                            <img src={instaIcon} className={HomeCss.iconSize} />
                        </a>
                        {/* <a>
                            <img src={facebookIcon} className={HomeCss.iconSize} />
                        </a>
                        <a>
                            <img src={xIcon} className={HomeCss.iconSize} />
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeScreen